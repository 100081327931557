@media screen and (min-width: 768px) {
  .modal-dialog {
    width: 500px;
  }
}
.modal-header{
  padding: 5px;
}
.modal-body {
  max-height: 65vh;
  overflow: auto;
  .m-b-20{
    margin-bottom: 20px;
  }
  &::-webkit-scrollbar {
    //display: none;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    outline: none;
    border-radius: 6px;
  }
}

.modal-footer {
  padding: 5px;
  text-align: center;
}