//Web
html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

.nav{
  li{
    .icons{
      position: relative;
      top: -2px;
      margin-right: 3px;
      font-size: 20px;
      vertical-align: middle;
    }
  }
}

.navbar-brand{
  padding: 6px;
  .logo{
    max-height: 40px;
  }
}

.strim_username {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//Fast animation
.animated {
  -webkit-animation-duration: 0.4s !important;
  animation-duration: 0.4s !important;
}

.dropdown-menu{
  min-width: 180px;
  li{
    padding: 0;
    a{
      padding: 1em;
      .icon-arrow-right{
        font-size: 12px;
        top: 0;
      }
    }
  }
}

.panel-default{
  margin-top: 20%;
  .panel-heading{
    text-align: center;
    a{
      color: #c8c8c8;
      text-decoration: none;
      .icon-close{
        font-size: 2.4em;
        vertical-align: middle;
      }
      &:hover{
        color: white;
      }
    }
  }
}

.form-group{
  &.m-t-2{
    margin-top: 2em;
  }
}

//Dev
.pointer {
  cursor: pointer;
}
.pointer-none{
  pointer-events: none;
}

.panel-body {
  padding: 8% 1em;
}

.affix, .affix-top{
  position: fixed;
  width: 100%;
}

.viewing{
  b{
    color: whitesmoke;
  }
}

#share_link{
  margin-top: 10px;
  display: inline-block;
}

#pin{
  margin-top: 6px;
}

@media screen and (min-width: 991px){
  .navbar{
    .container-fluid{
      padding: 0 5%;
    }
  }
  .navbar-brand{
    padding-right: 1em;
    margin-right: 1em;
  }
  .strim_username{
    max-width: 180px;
  }
}

@media screen and (max-width: 740px){
  .strim_username{
    max-width: 100%;
  }
  .navbar-default .navbar-nav .open .dropdown-menu>li>a{
    padding: 1em;
  }
}