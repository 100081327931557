//App
.note {
  max-width: 1000px;
  min-width: 150px;
  min-height: 100px;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  outline: none;
  box-shadow: 2px 2px 2px #666;
  &[theme="1"]{
    background-color: #ffff4d;
    background-image: -moz-linear-gradient(top, #ffffcc, #ffff4d);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffcc), to(#ffff4d));
  }
  &[theme="2"]{
    background-color: #66ccff;
    background-image: -moz-linear-gradient(top, #ccffff, #66ccff);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ccffff), to(#66ccff));
  }
  &[theme="3"]{
    background-color: #66cc66;
    background-image: -moz-linear-gradient(top, #ccffcc, #66cc66);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ccffcc), to(#66cc66));
  }
  &[theme="4"]{
    background-color: #ff9999;
    background-image: -moz-linear-gradient(top, #ffe6e6, #ff9999);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffe6e6), to(#ff9999));
  }
  &[theme="5"]{
    background-color: #ff471a;
    background-image: -moz-linear-gradient(top, #ff9980, #ff471a);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff9980), to(#ff471a));
  }
  &[theme="6"]{
    background-color: #cc66ff;
    background-image: -moz-linear-gradient(top, #eeccff, #cc66ff);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#eeccff), to(#cc66ff));
  }
  &[theme="7"]{
    background-color: #ffb84d;
    background-image: -moz-linear-gradient(top, #ffe0b3, #ffb84d);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffe0b3), to(#ffb84d));
  }
  &[theme="8"]{
    background-color: #bfbfbf;
    background-image: -moz-linear-gradient(top, #e6e6e6, #bfbfbf);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e6e6e6), to(#bfbfbf));
  }
  &[theme="9"]{
    background-color: #999999;
    background-color: rgba(0,0,0,.4);
    .editable{
      color: white;
    }
  }
  &[theme="10"]{
    background-color: #ffffff;
    background-color: rgba(255,255,255,.5);
  }
  .editable {
    width: 100% !important;
    height: 100% !important;
    min-height: 75px;
    color: #333;
    background: none;
    outline: none;
    cursor: pointer;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1em;
    -webkit-user-select: text;
    user-select: text;
    word-wrap: break-word;
    &.mce-edit-focus{
      cursor: text;
    }
    &::-webkit-scrollbar {
      //display: none;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #999;
      outline: none;
      border-radius: 6px;
    }
  }
  &.ui-draggable-dragging {
    outline: none;
  }
  .delete_note {
    img {
      position: absolute;
      right: 0;
      top: 0;
      margin-top: -10px;
      margin-right: -10px;
      border-radius: 100%;
      width: 26px;
      height: 26px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      z-index: 9;
    }
    &:hover, &:focus {
      img {
        width: 32px;
        height: 32px;
        margin-top: -13px;
        margin-right: -13px;
      }
    }
  }
  img.note_img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 991px) {
  .note{
    position: absolute !important;
    margin-bottom: 10em;
  }
}

@media screen and (max-width: 990px){
  #canvas {
    text-align: center;
    padding-top: 30%;
  }
  .note{
    position: relative;
    width: 80%;
    margin-bottom: 2em;
    text-align: left;
  }
}