.ui-resizable-e, .ui-resizable-s {
  display: none !important;
}

.mce-tinymce {
  border: none !important;
}

.mce-menu-item .mce-text{
  color: #999 !important;
}