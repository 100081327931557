.loading {
  position: fixed;
  right: 2em;
  bottom: 2em;
  display: inline-block;
  border: 0 solid rgba(255, 255, 255, .25);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 #333;
}

.loading:before {
  content: '';
  display: block;
  border: 0 solid transparent;
  border-top-color: rgba(0, 0, 0, .8);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: loading 1s ease infinite;
  -moz-animation: loading 1s ease infinite;
  -ms-animation: loading 1s ease infinite;
  -o-animation: loading 1s ease infinite;
  animation: loading 1s ease infinite;
}

.load {
  width: 38px;
  height: 38px;
  border-width: 5px;
}

.load:before {
  width: 38px;
  height: 38px;
  border-width: 5px;
  margin: -5px 0 0 -5px;
}

@-webkit-keyframes loading {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes loading {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-ms-keyframes loading {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-o-keyframes loading {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}