@charset "UTF-8";
p.checkbox {
  /* Base for label styling */
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */ }
  p.checkbox [type="checkbox"]:not(:checked),
  p.checkbox [type="checkbox"]:checked {
    position: absolute;
    left: -9999px; }
  p.checkbox [type="checkbox"]:not(:checked) + label,
  p.checkbox [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer; }
  p.checkbox [type="checkbox"]:not(:checked) + label:before,
  p.checkbox [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #aaa;
    background: #f8f8f8;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3); }
  p.checkbox [type="checkbox"]:not(:checked) + label:after,
  p.checkbox [type="checkbox"]:checked + label:after {
    content: '✓';
    position: absolute;
    top: 4px;
    left: 4px;
    font-size: 18px;
    line-height: 0.8;
    color: #09ad7e;
    transition: all .2s;
    font-weight: 700; }
  p.checkbox [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0); }
  p.checkbox [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1); }
  p.checkbox [type="checkbox"]:disabled:not(:checked) + label:before,
  p.checkbox [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd; }
  p.checkbox [type="checkbox"]:disabled:checked + label:after {
    color: #999; }
  p.checkbox [type="checkbox"]:disabled + label {
    color: #aaa; }
